<template>
  <div>
    <GenericTable
      ref="lista"
      name="Tipos de Perfuro Cortantes"
      title="Tipos de Perfuro Cortantes"
      add-route="tipoPerfuroCortanteAdicionar"
      edit-route="tipoPerfuroCortanteEditar"
      :nofilters="true"
      :cols="['Nome', 'Descrição']"
      :cols-name="['nome', 'descricao']"
      :cols-map="i => [i.nome, i.descricao]"
      :route="tipoPerfuroCortanteRoute"
      :permissionsToWrite="['rw_material']"
      idKey="id_tipo_perfuro_cortante"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipoPerfuroCortanteRoute: 'tipoPerfuroCortante',
    };
  },
};
</script>
